import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import PermissionControllers from '@/services/controllers/Permission'
import refreshToken from '@/mixins/auth/refreshToken'
import updateAllPermissions from '@/mixins/permissions/update'
import UserController from '@/services/controllers/User'
Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  
  routes: [
    // ** Public
    {
      name: 'Public',
      path: '/',
      component: () => import('@/views/public/Index'),
      props: true,
      children: [
        // ** Routes
        // Login
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/public/_auth/Login'),
        },

        // Register
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/public/_auth/Register'),
        },

        // Request Password Reset
        {
          name: 'RequestPasswordReset',
          path: 'password-reset-request',
          component: () => import('@/views/public/_auth/PasswordResetRequest'),
        },

        // PasswordReset
        {
          name: 'PasswordReset',
          path: 'password-reset',
          component: () => import('@/views/public/_auth/PasswordReset'),
        },

        // Download
        {
          name: 'Download',
          path: 'download',
          component: () => import('@/views/public/download/Index'),
        },

        // Verify Costing
        {
          name: 'VerifyCostCalculator',
          path: 'verify-costing',
          component: () => import('@/views/public/verify/Costing'),
        },

        // Pop Gallery
        {
          name: 'PopGallery',
          path: 'pop-gallery',
          component: () => import('@/views/public/pop/PopGallery'),
        },

         // Pop Gallery Landing
        {
          name: 'PopGalleryLand',
          path: 'pop-gallery-land',
          component: () => import('@/views/public/pop/Landing'),
        },
        
        // Creative flighting summary
        {
          name: 'CreativeFlightingSummary',
          path: 'p-cfs',
          component: () => import('@/views/public/cfs/CFS'),
        },

        // Privacy Policy
        {
          name: 'PrivacyPolicy',
          path: 'privacy-policy',
          component: () => import('@/views/public/legal/privacy/PrivacyPolicy'),
        },
      ],
    },

    // ** Dashboard
    {
      path: '/dashboard',
      name: 'Private',
      component: () => import('@/views/dashboard/Index'),
        
      children: [
        // ** _dev
        // DB Shortcuts
        {
          name:'DbShortcuts',
          path: '_dev/db-shortcuts',
          component: () => import('@/views/dashboard/_dev/DbShortcuts'),
          
          // Middleware
          // Only allow selected emails
          beforeEnter: async (to, from, next) => {
            if (store.state.User.user.email === 'greg@dooh.com' || store.state.User.user.email === 'haroon@dooh.com' || store.state.User.user.email === 'alan@dooh.com' || store.state.User.user.email === 'kelly@dooh.com' || store.state.User.user.email === 'patrick@dooh.com')
              next()
            else
              from()
          },
        },
        
        // Icon Library
        {
          name:'IconLibrary',
          path: '_dev/icon-library',
          component: () => import('@/views/dashboard/_dev/IconLibrary'),
          
          // Middleware
          // Only allow selected emails
          beforeEnter: async (to, from, next) => {
            if (store.state.User.user.email === 'tudor@dooh.com')
              next()
            else
              from() 
          },
        },
        
        // ** Dashboard
        // {
        //   name: 'Dashboard',
        //   path: '#',
        // },

        // ** Campaigns
        {
          name: 'Campaigns',
          path: 'campaigns',
          component: () => import('@/views/dashboard/campaigns/Index'),
          props: true 
        },

        // > Campaign
        {
          name: 'Campaign',
          path: 'campaigns/campaign',
          component: () => import('@/views/dashboard/campaigns/campaign/Index'),
          props: true 
        },

        // >> Creatives
        {
          name: 'Creatives',
          path: 'campaigns/campaign/creatives',
          component: () => import('@/views/dashboard/campaigns/campaign/creatives/Index'),
        },

        // >>> Artwork
        {
          name: 'Artwork',
          path: 'campaigns/campaign/creatives/artwork',
          component: () => import('@/views/dashboard/campaigns/campaign/creatives/Artwork'),
          props: true 
        },

        // >>> insitu-preview
        {
          name: 'InsituPreview',
          path: 'campaigns/campaign/creatives/insitu-preview',
          component: () => import('@/views/dashboard/campaigns/campaign/creatives/InsituPreview'),
        },

        // ** Formats (screens)
        {
          name: 'Formats',
          path: 'formats',
          component: () => import('@/views/dashboard/formats/Index'),

          // Middleware
          beforeEnter: (to, from, next) => {
            PermissionControllers.getBatchPermissions('(ScreenRead)').then(
              res => {
                const search = what =>
                  res.data.find(element => element.name === what)
                if (
                  search('ScreenRead').isAllowed === false
                ) {
                  next({
                    name: 'Campaigns',
                  })
                } else {
                  next()
                }
              },
            )
          },
        },

        // > Create Format
        {
          name: 'CreateFormat',
          path: 'formats/create',
          component: () => import('@/views/dashboard/formats/Create'),
          
          // Middleware
          beforeEnter: (to, from, next) => {
            PermissionControllers.getBatchPermissions('(ScreenCreate)').then(
              res => {
                const search = what => res.data.find(element => element.name === what)

                if (search('ScreenCreate').isAllowed === false)
                  next({ name: 'Campaigns' })
                else
                  next()
              },
            )
          },
        },

        // > Edit Format
        {
          name: 'EditFormat',
          path: 'formats/edit',
          component: () => import('@/views/dashboard/formats/Edit'),
          beforeEnter: function(from, to, next) {
            next()
          },
          meta: {
            beforeEach: (to, from, next) => {
              next();
            }
          }
        },

        // ** Reporting
        // > Set up
        {
          name: 'ReportingSetup',
          path: 'reporting/setup',
          component: () => import('@/views/dashboard/reporting/Setup'),
        },
        
        // > Generate
        {
          name: 'ReportingGenerate',
          path: 'reporting/generate',
          component: () => import('@/components/campaign/GenerateReport'),
        },
        
        // ** Stakeholders
        {
          name: 'Stakeholders',
          path: 'stakeholders',
          component: () => import('@/views/dashboard/stakeholders/Index'),

          // Middleware
          beforeEnter: (to, from, next) => {
            PermissionControllers.getBatchPermissions('(StakeholderRead)').then(
              res => {
                const search = what => res.data.find(element => element.name === what)
                
                if (search('StakeholderRead').isAllowed === false)
                  next({ name: 'Campaigns' })
                else
                  next()
              },
            )
          },
        },

        // ** Contacts
        {
          name: 'Contacts',
          path: 'contacts',
          component: () => import('@/views/dashboard/contacts/Index'),
        },
        // ** Broadsign
        {
          name: 'broadsign',
          path: 'broadsign/broadsign',
          component: () => import('@/views/dashboard/broadsign/broadsign'),
        },
        // ** Verify Costing
        // > Costing
        {
          name: 'VerifyCosting',
          path: 'verify/costing',
          component: () => import('@/views/dashboard/verify/Costing'),
        },

        // ** User
        {
          name: 'UserProfile',
          path: 'user/profile',
          component: () => import('@/views/dashboard/user/Profile'),
        },

        {
          name: 'EmailGenerator',
          path: 'user/emailGenerator',
          component: () => import('@/views/dashboard/user/EmailGenerator'),
        },

        // ** Proof of Play
        {
          name: 'ProofOfPlay',
          path: 'proof-of-play',
          component: () => import('@/views/dashboard/pop/PopGallery'),
        },

        // CampaignMap
        {
          name: 'CampaignMap',
          path: 'map',
          component: () => import('@/views/dashboard/map/CampaignMap'),
        },
        // Creative flighting summary
        {
          name: 'CFS',
          path: 'cfs',
          component: () => import('@/views/dashboard/cfs/CFS'),
          props: true
        },
        // 3d Scene
        {
          name: '3D',
          path: '3d',
          component: () => import('@/views/dashboard/3d/Index'),
          props: true
        },
        // 3d Scene
        {
          name: 'Notifications',
          path: 'notifications',
          component: () => import('@/views/dashboard/notifications/Index'),
          props: true
        },
        // Privacy Policy
        {
          name: 'PrivacyPolicy',
          path: 'privacy-policy',
          component: () => import('@/views/dashboard/legal/privacy/PrivacyPolicy'),
        },
      ],
    },
    
    // ** Catch all
    // 404 / Route not found
    {
      path: '*',
      component: () => import('@/views/public/Index'),
      children: [
        {
          name: '404',
          path: '',
          component: () => import('@/views/public/errors/404'),
        },
      ],
    },
  ],
})

// ** Router middleware
// This function fires before each route is loaded
router.beforeEach(async (to, from, next) => {
  // Shared local variables
  // Set to true by default because refreshToken is only checked on refresh
  let refreshTokenResult = true
  let userPermissionsResult = true

  // Dashboard Routes  
  if(to.path.includes('/dashboard')) {
 
    // Refresh token on page refresh
    if(!router.history.ready) {
      refreshTokenResult = await checkRefreshToken(to, from, next)
    }
    
    // Get users latest perms
    if (refreshTokenResult) {
      userPermissionsResult = await checkUserPermissions(to, from, next)
    }
    
    // If either variable has flipped to false, then redirect to login
    if(!refreshTokenResult || !userPermissionsResult) {
      next({ name: 'Login', query: { redirectFrom: to.fullPath } })
    }
      
    // Else continue to next route
    else {
      next()
    }
  }

  // Public Routes
  else {
    // Reserve root route for login
    if(to.name === 'Public' || !to.name) {
      if((window.location.href.includes('pop.') || window.location.href.includes('popstage.')) && !window.location.href.includes('pop-gallery'))
      {
        next({name: 'PopGallery' })
      }
      else
        next({ name: 'Login' })
    }

    // User logged in check
    else if(to.name === 'Login') {
      refreshTokenResult = await checkRefreshToken(to, from, next)
      
      // If not logged in, proceed
      if(!refreshTokenResult) {
        next()
      }
      
      // Else redirect
      else {
        next({ name: 'Campaigns' })
      }
    }

    // Else continue to next route
    else {
      next()
    }
  }
})

// Check the refresh token
const checkRefreshToken = async(to, from, next) => {
  let result = false

  // If nothing in local storage redirect
  if (localStorage.getItem("refreshToken")) {
    let { success } = await refreshToken()

    // If failed, redirect
    if(success)
      result = true
    else
      localStorage.removeItem("refreshToken");
  }

  return result
}

// Check  user perms
const checkUserPermissions = async(to, from, next) => {
  let result = true
  // Get user perms from end point before each route
  const res = await updateAllPermissions()
  /* Check isDarkMode
  localStorage.setItem('isDarkMode', true)
  await UserController.getUserSetting('NightMode').then(res => {
    localStorage.setItem('isDarkMode', res.data.value)
  }) */
      
  if(!res.data)
    result = false

  return result
}

export default router

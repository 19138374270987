import * as L from "leaflet"

let lastId = 0

export function stamp(obj) {
  if (!('_leaflet_id' in obj)) {
    obj['_leaflet_id'] = ++lastId
  }
  return obj._leaflet_id
}

const LeafletPlugin = {
  install(Vue) {
    Vue.prototype.$L = L
    Vue.prototype.$stamp = stamp
  }
};

export default LeafletPlugin;

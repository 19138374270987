<template v-if="$store.state.Permissions.userOnlineStatus">
  <v-alert type="warning" style="cursor: pointer;" class="mx-0 px-2 my-0 py-0" @click="reload" v-if="promptReload">
    A new version of the system is available. Click here to reload. <!-- v{{ systemVersion }}-->
  </v-alert>
  <!--
  <small class="mx-0 px-2 my-0 py-0" style="color: grey;" v-else>
   v{{ userSystemVersion }}
  </small>
  -->
</template>

<script>
import BespokeController from '@/services/controllers/Bespoke'
export default {
  props: ['navmaxitems'],
  data() {
    return {
      promptReload: false,
      systemVersion: null,
      userSystemVersion: null,
      awaitingSystemVersion: false,
      triggeredSystemVersionCheck: false
    };
  },
  async mounted() {
    
    // Checks the users browser state
    this.setEventListeners();

    await this.$router.afterEach(this.checkSystemVersion)
    
  },
  methods: {

    reload() {
      window.location.reload();
    },

    async checkSystemVersion() {
      if(this.awaitingSystemVersion) 
      {
        return;
      }
      this.awaitingSystemVersion = true;
      // get user system version from local storage
      this.userSystemVersion = localStorage.getItem('systemVersion');
      // get system version from server
      await BespokeController.getSystemVersion().then(res => {
        // save system version to local storage
        this.systemVersion = res.data
        localStorage.setItem('systemVersion', this.systemVersion)
        if(this.systemVersion != this.userSystemVersion)
        {
          // reload page
          this.promptReload = true;
        }
      }).finally(() => {
        this.awaitingSystemVersion = false;
      })
      
    },

    async setEventListeners() {
        var hidden = "hidden";

        var previousVisibilityState = "onload";
        // Standards:
        if (hidden in document)
          document.addEventListener("visibilitychange", onchange);
        else if ((hidden = "mozHidden") in document)
          document.addEventListener("mozvisibilitychange", onchange);
        else if ((hidden = "webkitHidden") in document)
          document.addEventListener("webkitvisibilitychange", onchange);
        else if ((hidden = "msHidden") in document)
          document.addEventListener("msvisibilitychange", onchange);
        // IE 9 and lower:
        else if ("onfocusin" in document)
          document.onfocusin = document.onfocusout = onchange;
        // All others:
        else
          window.onpageshow = window.onfocus; // only check on focus/show // = window.onpagehide = window.onblur = onchange;

          // Bind the context of 'this' to the Vue instance
          const self = this; // store the reference to the Vue component instance

          async function onchange(evt) {
              if(self.triggeredSystemVersionCheck)
              {
                return;
              }
              self.triggeredSystemVersionCheck = true;
              var v = "visible", h = "hidden",
                  evtMap = {
                      focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h
                  };

              evt = evt || window.event;
              var visibilityState = evt.type in evtMap ? evtMap[evt.type] : (this[hidden] ? "hidden" : "visible");
              var visibilityValue = visibilityState === "visible" ? 0 : 1;

              if (previousVisibilityState !== visibilityState) {
                  await self.checkSystemVersion();
                  previousVisibilityState = visibilityState;
                  self.triggeredSystemVersionCheck = false;
              }
          }
        // detect page change


        // set the initial state (but only if the browser supports the Page Visibility API)
        if (document[hidden] !== undefined)
          onchange({ type: document[hidden] ? "blur" : "focus" });
      },
  },
};
</script>